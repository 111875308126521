import React from "react"

interface TestimonialProps {
  author: string
  company: string
  text: string
}

const Testimonial = ({ author, company, text }: TestimonialProps) => {
  return (
    <div className="w-9/12 h-auto min-h-[50vh] pt-24">
      <h1 className="text-5xl font-main">{author}</h1>
      <h2 className="text-golden text-md">{company}</h2>
      <p className="mt-12 pb-8">{text}</p>
    </div>
  )
}

export default Testimonial
