import { Link } from "gatsby"
import React from "react"
import { useIsPolish } from "../../i18n"

interface BlogThumbnailProps {
  img: string
  title: string
  description: string
  date: string
  link?: string
}

const BlogThumbnail = ({
  img,
  title,
  description,
  date,
  link,
}: BlogThumbnailProps) => {
  const isPolish = useIsPolish()

  return (
    <div className="w-72 min-h-[30rem] bg-white rounded-md flex flex-col justify-between">
      <div className="text-black w-full font-content flex flex-col justify-between">
        <img
          className="w-full h-[12rem] object-cover rounded-t-md"
          src={img}
          alt="Cover image for blog post"
        />
        <div className="p-4 ">
          <h1 className="font-bold w-10/12 leading-[1.2]">{title}</h1>
          <p className="text-gray-500 text-xs mt-2">{description}</p>
        </div>
      </div>

      <div className="px-4 pb-4 font-content flex flex-row w-full justify-between">
        <i className="text-gray-500 text-xs">{date}</i>
        <a
          target="_blank"
          href={link || "#"}
          className="px-4 py-2 bg-golden rounded-md text-white"
        >
          {isPolish ? "Czytaj więcej" : "Read more"}
        </a>
      </div>
    </div>
  )
}

export default BlogThumbnail
