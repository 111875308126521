import React from "react"
import { TbBrandLinkedin } from "react-icons/tb"
import { FaPhoneAlt } from "react-icons/fa"
import { FiMail } from "react-icons/fi"
import { Link } from "gatsby"
import { useTranslation } from "../../i18n"
interface PersonProps {
  img: string
  topTitle: string
  bottomTitle: string
  description: string
  linkedIn: string
  phone: string
  email: string
  website: string
}

const Person = ({
  img,
  topTitle,
  bottomTitle,
  description,
  linkedIn,
  phone,
  email,
  website,
}: PersonProps) => {
  const { t } = useTranslation("person")

  return (
    <div className="w-[280px] h-[420px] relative overflow-hidden flex group">
      <img
        className={`rounded-sm relative duration-300 scale-110 transition z-30 grayscale group-hover:grayscale-0`}
        src={img}
        alt="Zdjęcie członka zespołu"
      />
      <div
        className={`duration-500 origin-[0] scale-y-0 group-hover:scale-y-[3.4] absolute opacity-95 z-40 w-[280px] mt-[430px] rounded-b-sm h-[150px] bg-white`}
      >
        <div className="scale-y-[0.294] -mt-[60px] text-black p-4 flex flex-col justify-start items-start">
          <h1 className="text-2xl">{topTitle}</h1>
          <h1 className="text-3xl">{bottomTitle}</h1>
          <h2 className="text-gray-500 text-sm font-content">{description}</h2>
          <div className="mt-4 w-full flex flex-row justify-between items-center">
            <div className="flex flex-row justify-center items-center">
              <Link to={linkedIn} target="_blank">
                <TbBrandLinkedin size={40} color="lightgray" className="mr-4" />
              </Link>
              <a href={`tel:${phone}`}>
                <FaPhoneAlt size={20} color="lightgray" className="mr-4" />
              </a>
              <a href={`mailto:${email}`}>
                <FiMail size={20} color="lightgray" className="mr-2" />
              </a>
            </div>
            <Link to={website}>
              <p className="font-content text-golden text-sm">
                {" "}
                {t("readMore")}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Person
