import React from "react"
import useMediaQuery from "../hooks/useMediaQuery"
import { AnchorLink } from "gatsby-plugin-anchor-links"

interface SlideProps {
  img: string
  imgMobile?: string
  link: string
}

const Slide = ({ img, imgMobile, link }: SlideProps) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)")

  return (
    <AnchorLink to={link} className="mx-auto shadow-xl">
      {isDesktop ? (
        <img src={img} alt="Carousel image" />
      ) : (
        <img src={imgMobile} alt="Carousel image" />
      )}
    </AnchorLink>
  )
}

export default Slide
