import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BlogThumbnail from "./BlogThumbnail"
import Container from "./Container"
import { useIsPolish } from "../../i18n"

const query = graphql`
  {
    allStrapiBlog {
      nodes {
        namePL
        nameEN
        descriptionPL
        descriptionEN
        date
        url
        img {
          url
        }
      }
    }
  }
`

const Blog: React.FC = () => {
  const isPolish = useIsPolish()

  return (
    <StaticQuery
      query={query}
      render={({ allStrapiBlog: { nodes } }) => (
        <Container>
          {console.log(nodes)}
          <div className="flex flex-col lg:flex-row items-center lg:justify-between gap-y-20 lg:gap-y-0 my-20 lg:my-0">
            {nodes.slice(0, 3).map((node, idx) => (
              <BlogThumbnail
                key={idx}
                img={node?.img?.url}
                title={isPolish ? node?.namePL : node?.nameEN}
                description={
                  isPolish ? node?.descriptionPL : node?.descriptionEN
                }
                date={node?.date}
                link={node?.url}
              />
            ))}
          </div>
        </Container>
      )}
    />
  )
}

export default Blog
