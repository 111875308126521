import React from "react"
import GoogleMapReact from "google-map-react"
import { RiMapPin2Fill } from "react-icons/ri"

const Map = () => {
  const defaultProps = {
    center: {
      lat: 51.996237265,
      lng: 20.241712255,
    },
    zoom: 8,
  }

  const createMapOptions = (maps: GoogleMapReact.Maps) => {
    return {
      scrolwheel: true, 
      mapTypeControl: false,
      streetViewControl: true,
      streetViewControlOptions: {
        position: maps.ControlPosition.RIGHT_BOTTOM,
      },
      styles: [
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#000000",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#000000",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#000000",
            },
            {
              lightness: 17,
            },
          ],
        },
      ],
    }
  }

  return (
    <div className="w-full h-[65vh]">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAzciS5tt0-3POGRggEM9vI7QcY5gy-5Vo",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}
      >
        <RiMapPin2Fill
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'lat' does not exist on type '{}'.
          lat={51.76125295938318}
          lng={19.486457291394448}
          color="white"
          size={40}
        />

        <RiMapPin2Fill
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'lat' does not exist on type '{}'.
          lat={52.231221569769424}
          lng={20.996967217718502}
          color="white"
          size={40}
        />
      </GoogleMapReact>
    </div>
  )
}

export default Map
