import { Disclosure, Transition } from "@headlessui/react"
import React, { useState } from "react"

import { MdKeyboardArrowDown } from "react-icons/md"

import { useTranslation } from "../../i18n"
interface QuestionProps {
  question: string
  answer: string
}

const Question = ({ question, answer }: QuestionProps) => {
  const { t } = useTranslation("Question")
  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={` ${
                open ? "" : "border-b-2"
              } duration-100 border-gray-300 w-full bg-parlourDark text-black font-content text-lg py-8 px-10 font-semibold flex flex-row justify-between items-center md:text-md text-left mb-3 last:mb-0`}
            >
              {question}
              <div className="flex items-end justify-end text-golden w-5/12">
                <p>{t("readMore")}</p>
                <MdKeyboardArrowDown
                  className={`${
                    open ? "rotate-180" : ""
                  } transition duration-500`}
                  size={32}
                />
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition duration-300 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="my-6 mx-8 font-content p-8">
                <div dangerouslySetInnerHTML={{ __html: answer }} className="prose !max-w-none"></div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Question
