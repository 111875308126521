import React, { useState } from "react"
import { Disclosure, Transition } from "@headlessui/react"
import Question from "./Question"
import { graphql, StaticQuery } from "gatsby"

import i18n, { useTranslation } from "../../i18n"
import { useIsPolish } from "../../i18n"

const query = graphql`
  {
    allStrapiService {
      nodes {
        category
        topic
        description {
          data {
            description
            childMarkdownRemark {
              html
            }
          }
        }
        topicEnglish
        descriptionEnglish {
          data {
            descriptionEnglish
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

const Faq = () => {
  const { t } = useTranslation("FAQ")
  const isPolish = useIsPolish()
  const [category, setCategory] = useState("szkolenia")

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <div className="mt-12 mb-24 text-black">
            <nav className="border-b-2 border-gray-300 text-gray-500 font-bold w-full flex flex-row justify-center">
              <button
                className={`py-10 w-3/12 text-xs md:text-sm lg:text-base flex justify-center items-center ${
                  category === "szkolenia"
                    ? "text-golden font-bold"
                    : "text-black"
                }`}
                onClick={() => {
                  setCategory("szkolenia")
                }}
              >
                {t("faq1")}
              </button>
              <button
                className={`py-10 w-3/12 text-xs md:text-sm lg:text-base  flex justify-center items-center ${
                  category === "polskilad"
                    ? "text-golden font-bold"
                    : "text-black"
                }`}
                onClick={() => {
                  setCategory("polskilad")
                }}
              >
                {t("faq2")}
              </button>
              <button
                className={`py-10 w-3/12  text-xs md:text-sm lg:text-base flex justify-center items-center ${
                  category === "doradztwo"
                    ? "text-golden font-bold"
                    : "text-black"
                }`}
                onClick={() => {
                  setCategory("doradztwo")
                }}
              >
                {t("faq3")}
              </button>
              <button
                className={`py-10 w-3/12 text-xs md:text-sm lg:text-base flex justify-center items-center ${
                  category === "vat" ? "text-golden font-bold" : "text-black"
                }`}
                onClick={() => {
                  setCategory("vat")
                }}
              >
                {t("faq4")}
              </button>
            </nav>
            {category === "szkolenia" && (
              <>
                {data.allStrapiService.nodes?.map(question => {
                  if (question.category === "szkolenia") {
                    return (
                      <Question
                        question={
                          isPolish ? question.topic : question.topicEnglish
                        }
                        answer={
                          isPolish
                            ? question.description.data.childMarkdownRemark.html
                            : question.descriptionEnglish.data.childMarkdownRemark.html
                        }
                      />
                    )
                  }
                })}
              </>
            )}

            {category === "polskilad" && (
              <>
                {data.allStrapiService.nodes?.map(question => {
                  if (question.category === "polskilad") {
                    return (
                      <Question
                        question={
                          isPolish ? question.topic : question.topicEnglish
                        }
                        answer={
                          isPolish
                            ? question.description.data.description
                            : question.descriptionEnglish.data
                                .descriptionEnglish
                        }
                      />
                    )
                  }
                })}
              </>
            )}

            {category === "doradztwo" && (
              <>
                {data.allStrapiService.nodes?.map(question => {
                  if (question.category === "doradztwo") {
                    return (
                      <Question
                        question={
                          isPolish ? question.topic : question.topicEnglish
                        }
                        answer={
                          isPolish
                            ? question.description.data.description
                            : question.descriptionEnglish.data
                                .descriptionEnglish
                        }
                      />
                    )
                  }
                })}
              </>
            )}

            {category === "vat" && (
              <>
                {data.allStrapiService.nodes?.map(question => {
                  if (question.category === "vat") {
                    return (
                      <Question
                        question={
                          isPolish ? question.topic : question.topicEnglish
                        }
                        answer={
                          isPolish
                            ? question.description.data.description
                            : question.descriptionEnglish.data
                                .descriptionEnglish
                        }
                      />
                    )
                  }
                })}
              </>
            )}
          </div>
        )
      }}
    />
  )
}

export default Faq
