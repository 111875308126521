import React, { useRef } from "react"
import Container from "../components/Container"
import Navbar from "../components/Navbar"
import Slide from "../components/Slide"
import Person from "../components/Person"

import { Splide, SplideSlide } from "@splidejs/react-splide"
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll"
import "@splidejs/react-splide/css"

import Bg from "../images/bg.webp"
import Banner from "../images/banner.webp"
import Team1 from "../images/team3.webp"
import Team2 from "../images/team4.webp"

import Agnieszka from "../images/team/agnieszka.webp"
import Tomasz from "../images/team/tomasz.webp"

import Left from "../images/left.png"
import Right from "../images/right.png"

import Alco from "../images/logo/alco.webp"
import Allianz from "../images/logo/allianz.webp"
import Adrea from "../images/logo/ardea.webp"
import Bromex from "../images/logo/bromex.webp"
import Clebre from "../images/logo/clebre.webp"
import Cobi from "../images/logo/cobi.webp"
import Creative from "../images/logo/creative.webp"
import Czerwony from "../images/logo/czerwony.webp"
import Ican from "../images/logo/ican.webp"
import Jutro from "../images/logo/jutro.webp"
import Kaya from "../images/logo/kaya.webp"
import MDM from "../images/logo/mdm.webp"
import Noctual from "../images/logo/noctual.webp"
import Opharm from "../images/logo/opharm.webp"
import Selium from "../images/logo/selium.webp"
import Semantive from "../images/logo/semantive.webp"
import Torusnet from "../images/logo/torusnet.webp"
import Testimonial from "../components/Testimonial"
import Faq from "../components/Faq"

import Blog1 from "../images/blog/blog1.webp"
import Blog2 from "../images/blog/blog2.webp"
import Blog3 from "../images/blog/blog3.webp"
import BlogThumbnail from "../components/BlogThumbnail"

import { BsTelephone } from "react-icons/bs"
import { FiMail } from "react-icons/fi"
import { TbWorld } from "react-icons/tb"

import Map from "../components/Map"
import Footer from "../components/Footer"
import { graphql, Link, StaticQuery } from "gatsby"

import i18n, { useTranslation } from "../../i18n"

import { useIsPolish } from "../../i18n"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import Blog from "../components/Blog"

const query = graphql`
  {
    allStrapiBanner {
      nodes {
        main {
          url
        }
        mainEnglish {
          url
        }
        mobile {
          url
        }
        mobileEnglish {
          url
        }
        link
      }
    }
    allStrapiTestimonial {
      nodes {
        company
        content
        name
        contentEnglish
      }
    }
    allStrapiLogos {
      nodes {
        img {
          url
        }
      }
    }
  }
`

function Head() {
  return (
    <>
      <title>Tax Harmony - Wiemy jak opodatkować e-commerce</title>
      <meta name="description" content="Konsultacje prawne, opodatkowanie e-commerce, VAT" />
    </>
  )
}

const IndexPage = () => {

  const isPolish = useIsPolish()

  const { t } = useTranslation("AboutUs")

  // Smooth scrolling
  const ref = useRef<HTMLDivElement>(null)

  return (
    <StaticQuery
      query={query}
      render={data => {
        return (
          <div>
            <Head />
            <Navbar ref={ref} />
            <section className="max-w-[1150px] mx-auto min-h-[95vh] flex justify-center items-center overflow-hidden relative md:static">
              <Swiper
                modules={[Navigation, Pagination]}
                navigation
                loop={true}
                spaceBetween={200}
                pagination={{ clickable: true }}
              >
                {data.allStrapiBanner.nodes?.map((banner: any) => {
                  return (
                    <SwiperSlide className="flex justify-center items-center">
                      <Slide
                        imgMobile={
                          isPolish === true
                            ? banner.mobile.url
                            : banner.mobileEnglish.url
                        }
                        img={
                          isPolish === true
                            ? banner.main.url
                            : banner.mainEnglish.url
                        }
                        link={banner.link}
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <img
                className="absolute z-0 top-[40vh] md:top-[20vh] left-[60vw] scale-[2] md:scale-100 md:left-4 ml-0  w-[200vw] sm:w-full md:w-6/12"
                src={Bg}
                alt="Background image"
              />
            </section>
            <section
              ref={ref}
              id="o-nas"
              className="relative !z-40 bg-gradient-to-t from-darkNavy to-navy shadow-xl text-left"
            >
              <Container>
                <div className="pt-32">
                  <h1 className="text-3xl leading-none"> {t("heading1")}</h1>
                  <h1 className="text-4xl ml-6 leading-none">
                    {" "}
                    {t("heading2")}
                  </h1>
                </div>
              </Container>

              <hr className="absolute right-0 top-[2rem] bg-gradient-to-r from-golden to-[#FBD87E] h-1 w-7/12 mt-32 border-0" />
              <Container>
                <div className="mt-16 flex flex-col lg:flex-row items-center justify-center font-content">
                  <p className="text-md mx-0 mb-10 lg:mb-0 lg:mx-auto lg:mr-20">
                    {t("para1a")} <b>{t("para1b")} </b>
                    {t("para1c")}
                    <b> {t("para1d")}</b>.
                    <br /> <br />
                    {t("para2")}
                  </p>
                  <img
                    className="shadow-2xl rounded-md w-[420px]"
                    src={Team1}
                    alt="A photo of us"
                  />
                </div>
                <div className="mt-24 flex flex-col lg:flex-row items-center justify-center font-content">
                  <img
                    className="shadow-2xl rounded-md w-[420px]"
                    src={Team2}
                    alt="A photo of us"
                  />
                  <p className="text-md mx-0 mt-10 lg:mt-0 lg:mx-auto lg:ml-20">
                    {t("para3a")}
                    <b> {t("para3b")}</b>.
                    <br /> <br />
                    {t("para3c")} <b>{t("para3d")}</b>.
                  </p>
                </div>
              </Container>
              <Container>
                <div className="mt-32 sm:mt-24 w-full min-h-[18rem] bg-gradient-to-tr from-golden to-[#FBD87E] flex flex-col">
                  <div className="m-1 min-h-[18rem] bg-[#1b1f2d] flex flex-col items-end justify-center">
                    <div className="w-full mx-auto sm:w-auto bg-gradient-to-t from-[#1b1f2d] to-[#1d212e] -mt-[6rem] text-center sm:text-left mr-12 px-2 sm:px-16">
                      <h1 className="text-3xl leading-none">{t("heading3")}</h1>
                      <h1 className="text-4xl ml-6 leading-none">
                        {t("heading4")}
                      </h1>
                    </div>
                    <p className="py-16 px-8 sm:px-32 font-content">
                      {t("ourMission1")}
                      <b> {t("ourMission2")}</b>
                      {t("ourMission3")}
                    </p>
                  </div>
                </div>
              </Container>
              <Container>
                <div className="mx-auto flex flex-col lg:flex-row items-center lg:items-start gap-y-[50px] lg:gap-y-0 justify-center gap-x-[50px] mt-24">
                  <Person
                    img={Agnieszka}
                    topTitle="Agnieszka"
                    bottomTitle="Pabiańska"
                    description={t("AgnieszkaRole")}
                    phone="+48223552952"
                    email="apabianska@taxharmony.pl"
                    linkedIn="https://www.linkedin.com/in/agnieszka-pabia%C5%84ska-99684289/"
                    website="/team/agnieszka"
                  />

                  <Person
                    img={Tomasz}
                    topTitle="Tomasz"
                    bottomTitle="Połeć"
                    description={t("TomaszRole")}
                    phone="+48223552952"
                    email="tpolec@taxharmony.pl"
                    linkedIn="https://www.linkedin.com/in/tomasz-po%C5%82e%C4%87-16274a52/"
                    website="/team/tomasz"
                  />
                </div>
              </Container>
              <div className="pb-24">
                <Container>
                  <div className="pt-32">
                    <h1 className="text-3xl leading-none"> {t("heading5")}</h1>
                    <h1 className="text-4xl ml-6 leading-none">
                      {" "}
                      {t("heading6")}
                    </h1>
                  </div>
                </Container>

                <hr className="absolute right-0 bottom-[8rem] bg-gradient-to-r from-golden to-[#FBD87E] h-1 w-7/12 mt-32 border-0" />
              </div>
            </section>
            <section className="flex flex-row justify-between">
              <div className="flex flex-col w-0 lg:w-auto">
                <img className="h-full" src={Left} alt="decoration" />
                <img className="h-full" src={Left} alt="decoration" />
              </div>
              <div className="font-main text-center pt-24 w-10/12 lg:w-6/12">
                <h1 className="text-4xl sm:text-5xl leading-none">
                  {t("heading7")}
                </h1>
                <h1 className="text-3xl sm:text-4xl ml-16 sm:ml-48 leading-none">
                  {t("heading8")}
                </h1>
                <div className="grid grid-cols-1 lg:grid-cols-3 my-24 gap-y-[4rem] gap-x-28">
                  {data.allStrapiLogos.nodes.map((logo, idx)=>{
                    return(
                      <div key={idx} className="mx-auto flex justify-center min-w-20 items-center grayscale hover:grayscale-0 duration-300 transition">
                        <img src={logo.img.url} alt="Logo partnera" />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="flex flex-col w-0 lg:w-auto">
                <img className="h-full" src={Right} alt="decoration" />
                <img className="h-full" src={Right} alt="decoration" />
              </div>
            </section>
            <section className="bg-[#f4f4f4] w-full shadow-xl">
              <div className="max-w-[1150px] mx-auto h-auto min-h-[50vh] flex justify-center items-center overflow-hidden relative md:static">
                <Splide
                  options={{
                    gap: "100px",
                    lazyLoad: true,
                    arrows: true,
                    rewind: true,
                    pagination: false,
                    type: "fade",
                  }}
                  aria-label="Main carousel"
                  className="z-20 "
                >
                  {data.allStrapiTestimonial.nodes?.map((testimonial: any) => {

                    return (
                      <SplideSlide className="flex justify-center">
                        <Testimonial
                          author={testimonial.name}
                          company={testimonial.company}
                          text={
                            isPolish
                              ? testimonial.content
                              : testimonial.contentEnglish
                          }
                        />
                      </SplideSlide>
                    )
                  })}
                </Splide>
              </div>
            </section>
            <section className="relative">
              <Container>
                <div id="oferta" className="pt-32 text-black">
                  <h1 className="text-3xl leading-none"> {t("heading9")}</h1>
                  <h1 className="text-4xl ml-6 leading-none">
                    {t("heading10")}
                  </h1>
                </div>
              </Container>

              <hr className="absolute right-0 top-[1rem] bg-gradient-to-r from-golden to-[#FBD87E] h-1 w-6/12 mt-32 border-0" />
              <Container>
                <Faq />
              </Container>
            </section>
            <section className="flex justify-center items-center relative overflow-hidden bg-gradient-to-t from-darkNavy to-navy shadow-xl min-h-[90vh]">
              <img
                className="opacity-50 h-[50rem] absolute top-[-3rem] right-[-10rem]"
                src={Bg}
                alt="decoration"
              />
              <Blog />
            </section>
            <section id="kontakt">
              <Container>
                <h1 className="text-black text-center text-4xl mt-28 mb-16">
                  {t("Contact")}
                </h1>
                <div className="border-y-2 border-gray-200 text-gray-400 font-content flex flex-col justify-center gap-x-10 gap-y-10  lg:flex-row py-8">
                  <div className="hover:text-golden">
                    <a
                      href="tel:+48223552952"
                      className="flex flex-row w-full justify-center items-center text-xl"
                    >
                      <BsTelephone size={32} className="mr-2" />{" "}
                      <p>+48 22 355 29 52</p>
                    </a>
                  </div>
                  <div className="hover:text-golden">
                    <a
                      href="mailto:tax@taxharmony.pl"
                      className="flex flex-row w-full justify-center items-center text-xl"
                    >
                      <FiMail size={32} className="mr-2" />{" "}
                      <p>tax@taxharmony.pl</p>
                    </a>
                  </div>
                  <div className="hover:text-golden">
                    <Link
                      to="https://jakopodatkowac.pl"
                      className="flex flex-row w-full justify-center items-center text-xl"
                    >
                      <TbWorld size={32} className="mr-2" />{" "}
                      <p>jakopodatkowac.pl</p>
                    </Link>
                  </div>
                </div>
                <div className="w-full flex flex-col sm:flex-row text-black mt-10 mb-32">
                  <div className="flex sm:justify-center items-center w-full sm:border-r-2 border-gray-200">
                    <div>
                      <h1 className="text-2xl"> {t("branchLodz1")}</h1>
                      <h1 className="text-5xl"> {t("branchLodz2")}</h1>
                      <h2 className="text-gray-500 font-content mt-2 leading-tight">
                        al. Piłsudskiego 85 <br /> 90-368 Łódź
                      </h2>
                    </div>
                  </div>
                  <div className="flex mt-8 sm:mt-0 sm:justify-center items-center w-full">
                    <div>
                      <h1 className="text-2xl"> {t("branchWWA1")}</h1>
                      <h1 className="text-5xl"> {t("branchWWA2")}</h1>
                      <h2 className="text-gray-500 font-content mt-2 leading-tight">
                        ul. Sienna 64 <br /> 00-825 Warszawa
                      </h2>
                    </div>
                  </div>
                </div>
              </Container>
            </section>
            <section>
              <Map />
            </section>
            <Footer />
          </div>
        )
      }}
    />
  )
}

export default IndexPage
